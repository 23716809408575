import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller } from "swiper";
import styled from "styled-components";

import { Maybe, SanityProduct } from "@graphql-types";
import { AbsoluteWrapper, Container, DesktopContainer, MobileContainer } from "@util/standard";
import { BlocksContent, Image } from "@global";
import { colors, colorsRGB, fontSizes, mediaQuery } from "@util/constants";
import { CircleArrow } from "@util/assets";

interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined;
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
  .swiper-slide {
    height: 230px;
    background: ${colorsRGB.charcoal(0.1)};
    opacity: 0.5;
    cursor: pointer;
    ${mediaQuery.largeDown} {
      height: 150px;
    }
  }

  .swiper-slide-active {
    height: 230px;
    opacity: 1;
    background: ${colors.white};

    ${mediaQuery.largeDown} {
      height: 150px;
    }
  }
`;

const NavWrapper = styled(AbsoluteWrapper)`
  display: flex;
  filter: invert(1);
  cursor: pointer;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);

  ${mediaQuery.largeDown} {
    position: static;
    transform: translateY(0%);
    width: 30%;
    margin: 0px auto 70px auto;
    justify-content: space-between;
  }
`;

const SlideContentWrapper = styled.div`
  width: 32%;
  max-width: 1000px;
  margin: 0 auto 145px auto;
  text-align: center;
  h2 {
    font-size: ${fontSizes.h1.default}px;
    margin: 10px 0;
  }

  ${mediaQuery.largeDown} {
    width: 80%;
    margin: 0 auto 70px auto;

    h2 {
      font-size: ${fontSizes.h2.mobile}px;
    }
  }
`;

const SecondarySlideWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function ProductsSlider({ data }: Props) {
  if (data == null) return null;

  const [controller, setController] = useState<SwiperCore>();
  const [secondaryController, setSecondaryController] = useState<SwiperCore>();

  return (
    <Container flexDirection="column" width="100%">
      <Container position="relative">
        <DesktopContainer>
          <NavWrapper left="10%" onClick={() => controller?.slidePrev()}>
            <CircleArrow />
          </NavWrapper>
          <NavWrapper right="10%" onClick={() => controller?.slideNext()}>
            <CircleArrow flip />
          </NavWrapper>
        </DesktopContainer>
        <Swiper
          modules={[Controller]}
          onSwiper={setController}
          controller={{ control: secondaryController }}
          loop
          slidesPerView={1}
          loopedSlides={data.length}
        >
          {data.map(product => {
            if (product == null) return null;

            const { productDescription, featureImage } = product;

            return (
              <SwiperSlide key={`swiper_1_${product.id}`}>
                <SlideContentWrapper>
                  {featureImage && <Image data={featureImage} width="100%" />}
                  {productDescription && <BlocksContent data={productDescription} />}
                </SlideContentWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <MobileContainer>
        <NavWrapper>
          <CircleArrow onClick={() => controller?.slidePrev()} />
          <CircleArrow flip onClick={() => controller?.slideNext()} />
        </NavWrapper>
      </MobileContainer>
      <Container width="100%">
        {controller && (
          <StyledSwiper
            modules={[Controller]}
            onSwiper={setSecondaryController}
            controller={{ control: controller }}
            slidesPerView={data.length < 4 ? data.length : 4}
            loop
            spaceBetween={5}
            loopedSlides={data.length}
            onClick={e => controller.slideTo(e.clickedIndex)}
          >
            {data.map((product, index) => {
              if (product == null) return null;

              const { secondaryImages } = product;

              return (
                <SwiperSlide key={`swiper_2_${product.id}${index}`}>
                  <SecondarySlideWrapper>
                    {secondaryImages && <Image data={secondaryImages[0]} width="100%" />}
                  </SecondarySlideWrapper>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        )}
      </Container>
    </Container>
  );
}

export default ProductsSlider;
